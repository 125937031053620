import React from "react";

const TreatmentMenu = ({ title, menuItems, color }) => {
  return (
    <div id={title} className="menubox">
      <h2 style={{ color: color }}>{title}</h2>
      <p>＊全て税込価格</p>
      <table className="table-menu">
        <thead>
          <tr>
            <th>メニュー</th>
            <th>初診</th>
            <th>2回目以降</th>
          </tr>
        </thead>
        <tbody>
          {menuItems.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td style={{ whiteSpace: "pre-wrap" }}>{item.firstVisit}</td>
              <td>{item.subsequentVisits}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>※効果については、個人差があります</p>
    </div>
  );
};

export default TreatmentMenu;
