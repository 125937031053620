import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";
import TreatmentMenu from "../components/treatment-menu";

export const Head = () => {
  return (
    <Seo
      title="鍼灸メニュー"
      description="体の痛み, 心の悩み、美容、鎮痛治療全般 初診4,900円等"
    />
  );
};

const MenuPage = () => {
  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "鍼灸メニュー",
      link: "/menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "コラム一覧",
      link: "/pages",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
    {
      id: 5,
      name: "アクセス・お問い合わせ",
      link: "/#storelist",
      dropdown: false,
    },
  ];
  // ここでデータを定義するか、あるいはAPIから取得するかします

  const bodyPainItems = [
    {
      name: "鍼灸治療全般",
      firstVisit: "4,900",
      subsequentVisits: "3,900",
    },
    {
      name: "首・肩・膝・腰",
      firstVisit: "5,400",
      subsequentVisits: "3,900",
    },
    {
      name: "スポーツ外傷",
      firstVisit: "4,500",
      subsequentVisits: "3,500",
    },
    {
      name: "介護障害",
      firstVisit: "5,500",
      subsequentVisits: "3,900",
    },
    {
      name: "更年期障害",
      firstVisit: "5,900",
      subsequentVisits: "4,900",
    },
    {
      name: "顔面神経麻痺",
      firstVisit: "6,900",
      subsequentVisits: "5,900",
    },
    {
      name: "気象病",
      firstVisit: "6,600",
      subsequentVisits: "4,900",
    },
    {
      name: "コロナ肥満",
      firstVisit: "5,900",
      subsequentVisits: "3,900",
    },
    {
      name: "育毛鍼",
      firstVisit: "5,500",
      subsequentVisits: "3,900",
    },
    {
      name: "眼精疲労・頭痛",
      firstVisit: "5,900",
      subsequentVisits: "4,900",
    },
  ];
  const mindItems = [
    {
      name: "自律神経失調症",
      firstVisit: "4,900",
      subsequentVisits: "3,900",
    },
    {
      name: "産後うつ",
      firstVisit: "6,900",
      subsequentVisits: "5,900",
    },
    {
      name: "長引くメンタル不調",
      firstVisit: "5,500",
      subsequentVisits: "4,900",
    },
    {
      name: "パニック障害",
      firstVisit: "5,500",
      subsequentVisits: "4,900",
    },
    {
      name: "不安神経症",
      firstVisit: "5,500",
      subsequentVisits: "3,900",
    },
    {
      name: "PMS・月経前後症候群",
      firstVisit: "5,500",
      subsequentVisits: "3,900",
    },
    {
      name: "更年期障害",
      firstVisit: "5,900",
      subsequentVisits: "4,900",
    },
    {
      name: "摂食障害",
      firstVisit: "6,900",
      subsequentVisits: "5,900",
    },
    {
      name: "不眠",
      firstVisit: "5,500",
      subsequentVisits: "3,900",
    },
    {
      name: "コロナうつ",
      firstVisit: "6,900",
      subsequentVisits: "5,900",
    },
  ];

  const beautyItems = [
    {
      name: "女性美顔鍼",
      firstVisit: "14,900",
      subsequentVisits: "72,000(5回)",
    },
    {
      name: "男性美顔鍼",
      firstVisit: "16,170",
      subsequentVisits: "16,170",
    },
    {
      name: "代謝改善",
      firstVisit: "4,900",
      subsequentVisits: "3,900",
    },
    {
      name: "姿勢改善・猫背調整",
      firstVisit: "5,500",
      subsequentVisits: "3,900",
    },
    {
      name: "骨盤調整・姿勢改善",
      firstVisit: "5,500",
      subsequentVisits: "3,900",
    },
    {
      name: "フォト美顔鍼",
      firstVisit: "25,000",
      subsequentVisits: "25,000",
    },
  ];

  const otherItems = [
    {
      name: "小児鍼",
      firstVisit: "4,500",
      subsequentVisits: "3,500",
    },
    {
      name: "逆子治療",
      firstVisit: "5,900",
      subsequentVisits: "4,900",
    },
  ];

  return (
    <Layout navLinks={navLinks} color={"rgb(64, 155, 57, 0.2)"}>
      <div className="kamata-body">
        <div className="kamata-body-text">
          <div className="kamata-menu-text-title">
            <h1>鍼 灸 メ ニ ュ ー</h1>
          </div>
        </div>
        <TreatmentMenu
          title={"「体の痛み」"}
          menuItems={bodyPainItems}
          color={"rgba(36, 122, 208, 1)"}
        />
        <TreatmentMenu
          title={"「心の悩み」"}
          menuItems={mindItems}
          color={"rgba(40, 111, 19, 1)"}
        />
        <TreatmentMenu
          title={"「 美 容 」"}
          menuItems={beautyItems}
          color={"rgba(208, 41, 208, 1)"}
        />
        <TreatmentMenu title={"「その他」"} menuItems={otherItems} />
        <div className="to-top-box">
          <Link to="/" className="menu-button">
            鍼灸整骨院トップページに戻る
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default MenuPage;
